<template>
  <!-- <div :style="{
      transform:
        'translateX(-50%) translateY(-50%) translateZ(0) scale(' + scale + ')'
    }" style="position: absolute; left: 50%; top: 50%; width: 1920px; height: 1080px">
    <Header/>
    <Main />
  </div> -->
  <dv-full-screen-container style="width:100%;height:100%">
    <Header />
    <Main />
  </dv-full-screen-container>
  
</template>

<script>
import Header from './components/Header.vue'
import Main from './components/Main/index.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main
  },
  data() {
    return {
      scale: 1,
    }
  },
  methods: {
    setScreen() {
      let visibleWidth = window.innerWidth;
      let visibleHeight = window.innerHeight;
      let manualWidth = 2220;
      let manualHeight = 1080;
      var widthPercentage = (1.0 * visibleWidth) / manualWidth;
      var heightPercentage = (1.0 * visibleHeight) / manualHeight;
      this.scale = Math.min(widthPercentage, heightPercentage);
    },
  },
  mounted() {
    //立即执行显示分辨率设置方法
    // this.setScreen();
    // window.addEventListener('resize', this.setScreen.bind(this));
    // document.oncontextmenu = function(ev) {
    //   ev.preventDefault();
    // };

    // window.addEventListener('resize', () => {
    //   document.querySelector('html').style.fontSize = 80 + 'px'
    // });
    // document.oncontextmenu = function(ev) {
    //   ev.preventDefault();
    // };
  },
}
</script>

<style lang="less">
@panelColor: #FFFFFF; // 02a6b5
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body, #app {
  width: 24rem;
  height: 13.5rem;
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden;
  background: url('./assets/images/bg.png') no-repeat;
  background-size: 100% 100%;
  // padding: 0 .25rem;
}

</style>
