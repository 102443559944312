<template>
  <div class="main-box">
    <div class="left-box">
      <div class="block panel dataDetection-box">
        <h3 class="title">
          <img :src="require('@/assets/images/t1.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item">
            <div class="item-title">场馆温/湿度监测</div>
            <div class="item-content">
              <div class="i">
                <img :src="require('@/assets/images/temperature.png')" alt="" srcset="">
                <div class="r">
                  <h4 class="title">室内温度</h4>
                  <div class="num">
                    <span>30.3</span>（℃）
                  </div>
                  <div class="status">过高</div>
                </div>
              </div>
              <div class="i">
                <img :src="require('@/assets/images/humidity.png')" alt="" srcset="">
                <div class="r">
                  <h4 class="title">室内湿度</h4>
                  <div class="num">
                    <span>30.3</span>（℃）
                  </div>
                  <div class="status decline">过低</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">场馆实时人数</div>
            <div class="echart" id="block1-2"></div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>

      <div class="block panel number-box">
        <h3 class="title">
          <img :src="require('@/assets/images/t2.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item-title">当前在馆人数</div>
          <div class="item-content">
            <div class="total-box">
              <span>0</span>
              <span>0</span>
              <span>1</span>
              <span>6</span>
              <span>6</span>
            </div>
            <div class="statistics-box">
              <div class="item">
                <span class="value">68</span>
                <span class="label">今日进馆</span>
              </div>
              <div class="item">
                <span class="value">52</span>
                <span class="label">今日出馆</span>
              </div>
              <div class="item">
                <span class="value">387</span>
                <span class="label">本周人数</span>
              </div>
              <div class="item">
                <span class="value">2546</span>
                <span class="label">历史人数</span>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>

      <div class="block panel proportion-box">
        <h3 class="title">
          <img :src="require('@/assets/images/t3.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item">
            <div class="item-title">运动类型占比</div>
            <div class="echart" id="block3-1"></div>
          </div>
          <div class="item">
            <div class="item-title">男女占比</div>
            <div class="echart" id="block3-2"></div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>
    </div>

    <div class="center-box">
      <div class="block panel">
        <h3 class="title">
          <img :src="require('@/assets/images/t3.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item">
            <div class="item-title">权益使用情况</div>
            <div class="item-content">
              <div class="group">
                <span>姓名</span>
                <span>日期</span>
                <span>使用情况</span>
              </div>
              <div class="group" v-for="(item, index) in 6" :key="index">
                <span>韩火火（会员）</span>
                <span>12.09 10:00</span>
                <span>1张20优惠券</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-title">享受权益人数</div>
            <div class="echart" id="block4-2"></div>
          </div>
          <div class="item">
            <div class="item-title">享受权益金额</div>
            <div class="echart" id="block4-3"></div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>
    </div>

    <div class="right-box">
      <div class="block panel internalScreen-box">
        <h3 class="title">
          <img :src="require('@/assets/images/t5.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item">
            <div class="item-title">场馆内部画面</div>
            <div class="video-box">
              <video controls style="width: 100%; height:100%; object-fit: cover">
                <source :src="require('@/assets/video.mp4')" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="item">
            <div class="item-title">接入的智能设备数量</div>
            <div class="equipment-box">
              <Progress name="AI捕捉镜头" :max="15" :value="2" />
              <Progress name="闸机" :max="15" :value="6" />
              <Progress name="租球柜" :max="15" :value="12" />
              <Progress name="售货机" :max="15" :value="8" />
              <Progress name="储物柜" :max="15" :value="10" />
              <Progress name="其它" :max="15" :value="11" />
            </div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>

      <div class="block panel ageDistribution-box">
        <h3 class="title">
          <img :src="require('@/assets/images/t6.png')" alt="">
        </h3>
        <div class="block-content">
          <div class="item">
            <div class="item-content">
              <div class="echart" id="block6-1"></div>
            </div>
          </div>
        </div>
        <div class="panel-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import progress from './progress.vue'
export default {
  name: 'MyMain',
  data() {
    return {

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {

      // 1-2
      this.getRealTimeNumber()
      // 3-1
      this.getSportsType()
      // 3-2
      this.getGender()
      // 4-2
      this.getEquityNumber()
      // 4-3
      this.getEquityAmount()
      // 6-1
      this.getAgeDistribution()
    },

    // 运动类型占比
    getSportsType() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block3-1')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} {b} : {c}次 ({d}%)"
        },
        legend: {
          orient: 'vertical',
          right: 0,
          bottom: '15%',
          icon: 'circle',
          itemHeight: 7,
          textStyle:{
            color: '#ffffff'//字体颜色
          }
        },
        color:['#04F5F7', '#2FD691', '#16B1FE'],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '60%'],
            center: ["46%", "40%"], //图表的位置
            label: {
              show: false
            },
            data: [
              { value: 1048, name: '篮球' },
              { value: 735, name: '羽毛球' },
              { value: 580, name: '乒乓球' },
            ]
          }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },

    // 男女占比
    getGender() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block3-2')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: "{b} : {c}人 ({d}%)"
        },
        legend: {
          orient: 'vertical',
          right: 0,
          bottom: '15%',
          icon: 'circle',
          itemHeight: 7,
          textStyle:{
            color: '#ffffff'//字体颜色
          }
        },
        color:['#16B1FE', '#E9B449'],
        
        series: [
          {
            name: '男女占比',
            type: 'pie',
            roseType: 'angle',
            center: ["46%", "40%"], //图表的位置
            label: {
              show: false
            },
            data: [
              { value: 1048, name: '男' },
              { value: 735, name: '女' },
            ]
          }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },
    
    // 权益人数
    getEquityNumber() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block4-2')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        // tooltip: {
        //   show: true,
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow',
        //     z:100,
        //     shadowStyle: {
        //     shadowColor: "rgba(167, 167, 167, 1)",
        //     shadowBlur: 10,
        //     color: "rgba(255, 255, 255, 1)"
              
        //     }
        //   },
        //   formatter: function(params) {
        //       console.log(params)
        //   },
        // },
        grid: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 20
        },
        xAxis: [{
            type: 'category',
            axisTick: {
                show: false,
                alignWithLabel: true, 
            },
            axisLine: {
                lineStyle: {
                    color: "#889fcc"
                }
            },
            data: ['1月', '2月', '3月', '4月']
        }, ],
        yAxis: [{
            type: 'value',
            max: 800,
            min: 0,
            splitNumber: 5,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "rgba(136, 159, 204, .2)"
                }
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: "#889fcc"
                }
            },
            axisTick: {
                show: false
            },
        }],
        series: [
          {
            z:200,
            type: 'line',
            smooth: true,
            symbolSize: 15,
            showSymbol: false,
            itemStyle: {
                normal: {
                    show: false,
                    color: "#3282FF", //改变折线点的颜色
                    lineStyle: {
                        color: "#FEBE27" //改变折线颜色
                    },
                    label: {
                        show: false, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: '#999999',
                            fontSize: 10
                        }
                    },

                },
                emphasis: {
                    show: true,
                    color: "#3282FF",
                    borderColor: "#ffffff",
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: '##fff',
                            fontSize: 14,
                        }
                    }
                }
            },
            areaStyle: {
                normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [{
                                offset: 0,
                                color: 'rgba(50,130,255,0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(50,130,255,0)'
                            }
                        ],
                        false
                    ),
                    shadowColor: '#FED30C',
                    shadowBlur: 10
                }
            },
            data: [200,100,160,120]
        },
        {
          z:200,
          type: 'bar',
          barWidth: '5',
          itemStyle: {
            normal: {
                color:"transparent"
            },
            emphasis: {
                color: '#2B75D4',
            }
          },
          data: [200,100,160,120]
        }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },

    // 权益金额
    getEquityAmount() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block4-3')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        grid: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 20
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 8],
            type: 'bar'
          }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },

    // 实时人数
    getRealTimeNumber() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block1-2')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        // tooltip: {
        //   show: true,
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow',
        //     z:100,
        //     shadowStyle: {
        //     shadowColor: "rgba(167, 167, 167, 1)",
        //     shadowBlur: 10,
        //     color: "rgba(255, 255, 255, 1)"
              
        //     }
        //   },
        //   formatter: function(params) {
        //       console.log(params)
        //   },
        // },
        grid: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 20
        },
        xAxis: [{
            type: 'category',
            axisTick: {
                show: false,
                alignWithLabel: true, 
            },
            axisLine: {
                lineStyle: {
                    color: "#889fcc"
                }
            },
            data: ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00']
        }, ],
        yAxis: [{
            type: 'value',
            max: 40,
            min: 0,
            splitNumber: 5,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "rgba(136, 159, 204, .2)"
                }
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: "#889fcc"
                }
            },
            axisTick: {
                show: false
            },
        }],
        series: [
          {
            z:200,
            type: 'line',
            smooth: true,
            
            showSymbol: false,
            itemStyle: {
                normal: {
                    show: false,
                    color: "#3282FF", //改变折线点的颜色
                    lineStyle: {
                        color: "#FEBE27" //改变折线颜色
                    },
                    label: {
                        show: false, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: '#999999',
                            
                        }
                    },

                },
                emphasis: {
                    show: true,
                    color: "#3282FF",
                    borderColor: "#ffffff",
                    label: {
                        show: true, //开启显示
                        position: 'top', //在上方显示
                        textStyle: { //数值样式
                            color: '##fff',
                        }
                    }
                }
            },
            areaStyle: {
                normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                        0,
                        0,
                        0,
                        1,
                        [{
                                offset: 0,
                                color: 'rgba(50,130,255,0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(50,130,255,0)'
                            }
                        ],
                        false
                    ),
                    shadowColor: '#FED30C',
                    shadowBlur: 10
                }
            },
            data: [10,22,24,8,14,6,28]
        },
        {
          z:200,
          type: 'bar',
          barWidth: '5',
          itemStyle: {
            normal: {
                color:"transparent"
            },
            emphasis: {
                color: '#2B75D4',
            }
          },
          data: [10,22,24,8,14,6,28]
        }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },

    // 年龄分布
    getAgeDistribution() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('block6-1')
      var myChart = this.$echarts.init(chartDom);
      // 绘制图表
      var option = {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: "{b} : {c}人 ({d}%)"
        // },
        legend: {
          orient: 'vertical',
          right: 0,
          bottom: '15%',
          icon: 'circle',
          itemHeight: 7,
          textStyle:{
            color: '#ffffff'//字体颜色
          }
        },
        // color:['#16B1FE', '#E9B449'],
        
        series: [
          {
            name: '男女占比',
            type: 'pie',
            roseType: 'angle',
            center: ["46%", "40%"], //图表的位置
            label: {
              show: false
            },
            data: [
              { value: 68, name: '20岁以下' },
              { value: 78, name: '21-40岁' },
              { value: 116, name: '41-60岁' },
              { value: 123, name: '60岁以上' },
            ]
          }
        ]
      };
      option && myChart.setOption(option);

      // 实现图表自适应
      this.echartResize(myChart, chartDom)
    },
  },
  components: {
    Progress: progress
  }
}
</script>

<style lang="less">
.main-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 .25rem;
  padding-top: .7125rem;

  /* 公共样式start */
  h3.title {
    width: 6.775rem;
    height: .4875rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .panel {
    position: relative;
    .panel-footer {
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: .1rem;
        height: .1rem;
        border-bottom: .025rem solid #FFFFFF;
        border-left: .025rem solid #FFFFFF;
      }
      &::after {
        position: absolute;
        bottom: 0;
        right: 0;
        content: "";
        width: .1rem;
        height: .1rem;
        border-bottom: .025rem solid #FFFFFF;
        border-right: .025rem solid #FFFFFF;
      }
    }
    
  }

  .block {
    position: relative;
    width: 6.875rem;
    height: 3.75rem;
    // background-color: red;
    margin-bottom: .25rem;
    &:last-child {
      height: 4rem;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background: linear-gradient(0deg, #223B55 0%, rgba(34,59,85,0) 100%);
      opacity: 0.5; 
    }
    .block-content {
      display: flex;
      justify-content: space-between;
      .item-title {
        display: flex;
        align-items: center;
        font-size: .2rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #04F3F7;
        line-height: .275rem;
        padding: .20rem .25rem;
        &::before {
          content: '';
          display: inline-block;
          width: .05rem;
          height: .1875rem;
          background: #04F3F7;
          border-radius: .025rem;
          margin-right: .075rem;
        }
      }

      .echart {
        width: 90%;
        height: 90%;
      }
    }
  }
  /* 公共样式end */

  .left-box {
    .dataDetection-box {
      .item {
        &:first-child {
          .item-content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 2.45rem;
            height: 2.3625rem;
            margin-left: .225rem;
            &::before {
              content: '';
              width: 2.45rem;
              height: 2.3625rem;
              display: block;
              position: absolute;
              background: linear-gradient(0deg, #264F86 0%, rgba(38,79,134,0.2) 100%);
              opacity: 0.3;
            }
            .i {
              display: flex;
              justify-content: space-around;
              align-items: center;
              img {
                width: .925rem;
                height: .5rem;
              }
              .r {
                h4.title {
                  font-size: .175rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                }
                .num {
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  color: #AED4D4;
                  font-size: .15rem;
                  margin-top: .0875rem;
                  span {
                    color: #04F5F7;
                    font-size: .3rem;
                  }
                }
                .status {
                  display: flex;
                  align-items: center;
                  font-size: .15rem;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FE3C57;
                  &::after {
                    display: block;
                    content: '';
                    width: .1125rem;
                    height: .1375rem;
                    background: url(../../assets/images/rise.png) 0px 0px no-repeat;
                    background-size: 100% 100%;
                    margin-left: .025rem
                  }
                  &.decline {
                    color: #3FFF71;
                    &::after {
                      background: url(../../assets/images/decline.png) 0px 0px no-repeat;
                    }
                  }
                }
              }
            }
          }
          
        }

        &:last-child {
          width: 3.8rem;
          height: 2.3625rem;
          .item-title {
            padding-left: 0;
          }
          .echart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .number-box {
      .item {
        width: 100%;
        
      }
      .block-content {
        display: block;
        .item-content {
          padding: 0 .25rem;
        }
      }
      
      .total-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          width: 1.125rem;
          height: 1.125rem;
          text-align: center;
          background: rgba(9,37,37,0.35);
          // opacity: 0.6;
          font-size: .625rem;
          font-family: PangMenZhengDao;
          font-weight: 400;
          color: #04F5F7;
          line-height: 1.125rem;
          background: url(../../assets/images/num-bg.png) 0px 0px no-repeat;
          background-size: 100% 100%;
        }
      }

      .statistics-box {
        display: flex;
        justify-content: space-between;
        margin-top: .375rem;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 1.55rem;
          height: .75rem;
          background: rgba(49,182,244,0.1);
          border-radius: .075rem;
          .label {
            font-size: .15rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3EC5FD;
            line-height: .225rem;
            margin-top: .0625rem;
          }
          .value {
            font-size: .25rem;
            font-family: PangMenZhengDao;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .225rem;
          }
        }
      }
    }

    .proportion-box {
      .block-content {
          .item {
            width: 3.3625rem;
            height: 3.125rem;
            .echart {
              position: relative;
              &::before {
                position: absolute;
                content: '';
                display: inline-block;
                width: 3.3625rem;
                height: 2.625rem;
                // background: #000;
                background: linear-gradient(0deg, #223B55 0%, rgba(34,59,85,0) 100%);
                opacity: 0.5;
              }
            }
          }
        }
    }
  }

  .center-box {
    display: flex;
    align-items: flex-end;
    .block {
      width: 9.25rem;
      &::before {
        opacity: 0.3;
      }
      .block-content {
        .item {
          &:nth-child(1) {
            .group {
              display: flex;
              justify-content: space-between;
              width: 3.75rem;
              padding-left: .375rem;
              span {
                font-size: .1625rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: .375rem;
              }
            }
            
          }
          &:nth-child(2), &:nth-child(3) {
            width: 2.5rem;
            .item-title {
              padding-left: 0;
            }
            .echart {
              width: 100%;
              height: 80%;
            }
          }
        }
      }
    }
  }

  .right-box {
    .block {
      &:last-child {
        &::before {
          opacity: 0.3;
        }
      }
      &.internalScreen-box {
        height: 7.75rem;
        .block-content {
          display: block;
          // 视频
          .video-box {
            width: 6.425rem;
            height: 3.5625rem;
            margin: 0 auto;
            background: #75759F;
            border-radius: .05rem;
          }
          // 设备数量
          .equipment-box {
            display: grid;
            flex-wrap: wrap;
            grid-template-columns: repeat(auto-fill, 50%);
            padding-left: .25rem;
          }
        }
        
      }
      &.ageDistribution-box {
        .block-content {
          .item-content {
            width: 6.3375rem;
            height: 2.9125rem;
            border-radius: .075rem;
            margin: .375rem auto 0;
            // &::before {
            //   display: block;
            //   content: '';
            //   width: 6.3375rem;
            //   height: 2.9125rem;
            //   border-radius: .075rem;
            // }
          }
        }
      }
    }
  }
}
</style>
