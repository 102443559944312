import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import BinDatav from 'bin-datav'
import 'bin-datav/lib/styles/index.css'
import '@/assets/js/flexible'



import * as echarts from 'echarts'
import 'echarts-gl' // 3d图表库
// 实现图表自适应
import { EleResize } from '@/assets/js/esresize'

const app = createApp(App)
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.echartResize = (myChart, chartDom) => {
  let listener = () => {
    myChart.resize()
  }
  EleResize.on(chartDom, listener)
}

app.use(store).use(BinDatav).use(echarts).mount('#app')
