<template>
  <header class="header-box">
    <div class="weather">大雨  18℃~22℃  PM2.5（10）</div>
    <!-- <div class="light"></div> -->
    <div class="time" v-html="currentTime"></div>
  </header>
</template>

<script>
let weekArr = [
  "星期日",
  "星期一",
  "星期二",
  "星期三",
  "星期四",
  "星期五",
  "星期六",
]
export default {
  name: 'MyHeader',
  data() {
    return {
      currentTime: ''
    }
  },
  created() {
    this.getCurrentTime()
    setInterval(() => {
      this.getCurrentTime()
    }, 1000)
  },
  methods: {
    getCurrentTime() {
      const dt = new Date();
      var y = dt.getFullYear();
      var mt = dt.getMonth() + 1;
      var day = dt.getDate();
      var h = dt.getHours(); //获取时
      var m = dt.getMinutes(); //获取分
      // var s = dt.getSeconds(); //获取秒
      let week = dt.getDay(); // 星期
      this.currentTime = `${y}-${mt>=10?mt:'0'+mt}-${day>=10?day:'0'+day} &nbsp; ${weekArr[week]} &nbsp; ${h>=10?h:'0'+h}:${m}`

      // 2022-09-14  周三  11:23
      console.log();
    }
  }
}
</script>

<style lang="less" scoped>
  .header-box {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: .8875rem;
    padding: 0 .25rem;
    // background: url(../assets/images/header.png) .275rem 0px no-repeat;
    background-size: 100% 100%;

    .weather {
      font-size: .175rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #D5EBF2;
      line-height: .275rem;
    }

    .light {
      position: relative;
      width: 34%;
      height: 1.075rem;
      line-height: .8875rem;
      margin: 0 auto;
      // background-color: red;
      overflow: hidden;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -0.6rem;
        width: 7.3rem;
        height: 100px;
        background: url(../assets/images/light.png) no-repeat;
        // transition-timing-function: linear;
         /*使用关键帧  watermove  9s播放  匀速 无限循环*/
        animation: mymove 4.5s linear infinite alternate;
      }
    }

    .time {
      font-size: .175rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #D5EBF2;
      line-height: .275rem;
    }

    @keyframes mymove {
      from {transform: translateX(-1rem)}
      to {transform: translateX(2.5rem)}
    }
  }
</style>