<template>
  <div class="progress-box">
    <div class="progress-name">{{ name }}</div>
    <div class="progress-bar">
      <div class="progress-bar-outer">
        <div class="progress-bar-inner"></div>
      </div>
      <div class="num">
        <span>{{ value }}</span>个
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myProgress',
  props:{
     name:{
         type:String
     },
     value: {
      type: Number
     }
   },
  setup() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.progress-box {
  margin-bottom: .175rem;
  .progress-name {
    font-size: .175rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #DEEBFF;
    line-height: .3rem;
  }
  .progress-bar {
    display: flex;
    align-items: center;
    .progress-bar-outer {
      width: 2.5rem;
      height: .125rem;
      background: #20384E;
      border-radius: .025rem;
      .progress-bar-inner {
        width: 80%;
        height: .125rem;
        background: #04F5F7;
        border-radius: .025rem;
      }
    }
    .num {
      font-size: .175rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #DEEBFF;
      margin-left: .1375rem;
      span {
        display: inline-block;
        color: #04F5F7;
      }
    }
  }
  
}
</style>
